import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import { setDefaultTranslations, setDefaultLanguage } from "react-multi-lang";
import en from "./translations/en.json";

setDefaultTranslations({ en });
setDefaultLanguage("en");

ReactDOM.render(
    <MuiPickersUtilsProvider utils={MomentUtils}>
        <App />
    </MuiPickersUtilsProvider>,
    document.getElementById("root")
);
