import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ArrowBack from "@material-ui/icons/ArrowBack";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import { DatePicker } from "@material-ui/pickers";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import LinearProgress from "@material-ui/core/LinearProgress";

import { translate, getLanguage } from "react-multi-lang";
import moment from "moment";
import fetchClient from "../components/utils/fetchClient";

import withAuth from "../components/auth/withAuth";

const localeMap = {
    en: "en",
    es: "es"
};

const styles = theme => ({
    root: {
        width: "100%"
    },
    grow: {
        flexGrow: 1
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20
    },
    content: {
        paddingLeft: theme.spacing(),
        paddingTop: theme.spacing()
    },
    button: {
        margin: theme.spacing()
    },
    flex: {
        flex: 1
    },
    formControl: {
        margin: theme.spacing(),
        minWidth: 300
    },
    datePicker: {
        padding: theme.spacing(),
        width: 300
    }
});

class Request extends React.Component {
    constructor() {
        super();

        this.state = {
            isLoading: true,
            isDisabled: true,
            newRequest: {
                requestStart: null,
                requestDate: null,
                requestEnd: null,
                requestType: 1,
                requestHours: 0,
                requestComment: ""
            },
            agent: {
                holidayDaysUsed: 0,
                holidayDaysRemaining: 0,
                holidayAllowance: 0
            },
            currentLang: getLanguage()
        };

        moment.locale(this.state.currentLang);

        this.newRequest = this.newRequest.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleDateChangeEnd = this.handleDateChangeEnd.bind(this);
        this.handleDateChangeStart = this.handleDateChangeStart.bind(this);
    }

    handleDateChange(date) {
        this.setState({ newRequest: { ...this.state.newRequest, requestDate: moment(date).format("YYYY-MM-DD") } }, () => {
            this.checkforErrors();
        });
    }

    handleDateChangeStart(date) {
        this.setState({ newRequest: { ...this.state.newRequest, requestStart: moment(date).format("YYYY-MM-DD") } }, () => {
            this.checkforErrors();
        });
    }

    handleDateChangeEnd(date) {
        this.setState({ newRequest: { ...this.state.newRequest, requestEnd: moment(date).format("YYYY-MM-DD") } }, () => {
            this.checkforErrors();
        });
    }

    handleChange = event => {
        this.setState({ newRequest: { ...this.state.newRequest, [event.target.name]: event.target.value } }, () => {
            this.checkforErrors();
        });
    };

    handleTypeChange(type) {
        this.setState({ newRequest: { requestType: type, requestHours: 0, requestStart: null, requestEnd: null, requestDate: null, requestComment: "" } }, () => {
            this.checkforErrors();
        });
    }

    newRequest() {
        var self = this;

        self.setState({ isDisabled: true });

        fetchClient
            .post("workscheduler/request", {
                ...self.state.newRequest
            })
            .then(function (response) {
                self.setState({
                    newRequest: [],
                    isDisabled: true
                });
                self.props.history.push("/");
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async componentDidMount() {
        let me = await this.getMe();

        this.setState({
            agent: {
                holidayDaysRemaining: parseInt(me.holidayAllowance - me.holidayUsed),
                holidayDaysUsed: me.holidayUsed,
                holidayAllowance: me.holidayAllowance
            },
            isLoading: false
        });
    }

    async getMe() {
        return new Promise(function (resolve, reject) {
            fetchClient
                .get("me")
                .then(function (response) {
                    resolve(response.data.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    checkforErrors() {
        let errorCount = 0;

        if (!this.state.newRequest.requestType) {
            errorCount++;
        }

        if (!this.state.newRequest.requestHours && this.state.newRequest.requestType !== 2) {
            errorCount++;
        }

        if (!this.state.newRequest.requestComment && this.state.newRequest.requestTyp !== 2) {
            errorCount++;
        }

        if (!this.state.newRequest.requestDate && this.state.newRequest.requestType !== 2) {
            errorCount++;
        }

        if (!this.state.newRequest.requestStart && this.state.newRequest.requestType === 2) {
            errorCount++;
        }

        if (!this.state.newRequest.requestEnd && this.state.newRequest.requestType === 2) {
            errorCount++;
        }

        this.setState({ isDisabled: errorCount > 0 ? true : false });
    }

    render() {
        const { classes, t } = this.props;
        const locale = localeMap[this.state.currentLang];

        return (
            <div className={classes.root}>
                <AppBar position="static">
                    <Toolbar>
                        <IconButton
                            onClick={() => {
                                this.props.history.push("/");
                            }}
                            className={classes.menuButton}
                            color="inherit"
                            aria-label={t("misc.Back")}
                        >
                            <ArrowBack />
                        </IconButton>
                        <Typography variant="h6" color="inherit" className={classes.grow}>
                            {t("titles.Request")}
                        </Typography>
                        <Button color="inherit" disabled={this.state.isDisabled} onClick={this.newRequest}>
                            {t("Request.Submit")}
                        </Button>
                    </Toolbar>
                </AppBar>
                {this.state.isLoading ? (
                    <LinearProgress color="secondary" />
                ) : (
                    <div className={classes.content}>
                        <p>{t("Request.Explanation")}</p>

                        <Typography variant="h6">{t("Request.TypeRequest")}</Typography>

                        <Button
                            variant="contained"
                            color={this.state.newRequest.requestType === 1 ? "primary" : "default"}
                            onClick={() => {
                                this.handleTypeChange(1);
                            }}
                            className={classes.button}
                        >
                            {t("Request.Overhours")}
                        </Button>

                        <Button
                            variant="contained"
                            color={this.state.newRequest.requestType === 2 ? "primary" : "default"}
                            onClick={() => {
                                this.handleTypeChange(2);
                            }}
                            className={classes.button}
                        >
                            {t("Request.Holidays")}
                        </Button>
                        <Button
                            variant="contained"
                            color={this.state.newRequest.requestType === 3 ? "primary" : "default"}
                            onClick={() => {
                                this.handleTypeChange(3);
                            }}
                            className={classes.button}
                        >
                            {t("Request.Underhours")}
                        </Button>

                        <br />

                        {this.state.newRequest.requestType === 1 && (
                            <div>
                                <div className={classes.datePicker}>
                                    <DatePicker
                                        label={t("Request.Date")}
                                        name="requestDate"
                                        required
                                        error={!Boolean(this.state.newRequest.requestDate)}
                                        locale={locale}
                                        fullWidth={true}
                                        format="DD/MM/YYYY"
                                        autoOk={true}
                                        value={this.state.newRequest.requestDate}
                                        onChange={this.handleDateChange}
                                    />
                                </div>

                                <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="requestHours"> {t("Request.NumHours")}</InputLabel>
                                    <Select
                                        native
                                        value={this.state.requestHours}
                                        onChange={this.handleChange}
                                        error={!Boolean(this.state.newRequest.requestHours)}
                                        inputProps={{
                                            name: "requestHours",
                                            id: "requestHours"
                                        }}
                                    >
                                        <option value="" />
                                        <option value={0.5}>30 {t("misc.Minutes")}</option>
                                        <option value={1}>1 {t("misc.Hour")}</option>
                                        <option value={1.5}>
                                            1 {t("misc.Hours")} 30 {t("misc.Minutes")}
                                        </option>
                                        <option value={2}>2 {t("misc.Hours")}</option>
                                        <option value={2.5}>
                                            2 {t("misc.Hours")} 30 {t("misc.Minutes")}
                                        </option>
                                        <option value={3}>3 {t("misc.Hours")}</option>
                                        <option value={3.5}>
                                            3 {t("misc.Hours")} 30 {t("misc.Minutes")}
                                        </option>
                                        <option value={4}>4 {t("misc.Hours")}</option>
                                        <option value={4.5}>
                                            4 {t("misc.Hours")} 30 {t("misc.Minutes")}
                                        </option>
                                        <option value={5}>5 {t("misc.Hours")}</option>
                                        <option value={5.5}>
                                            5 {t("misc.Hours")} 30 {t("misc.Minutes")}
                                        </option>
                                        <option value={6}>6 {t("misc.Hours")}</option>
                                        <option value={6.5}>
                                            6 {t("misc.Hours")} 30 {t("misc.Minutes")}
                                        </option>
                                        <option value={7}>7 {t("misc.Hours")}</option>
                                        <option value={7.5}>
                                            7 {t("misc.Hours")} 30 {t("misc.Minutes")}
                                        </option>
                                        <option value={8}>8 {t("misc.Hours")}</option>
                                    </Select>
                                </FormControl>
                            </div>
                        )}

                        {this.state.newRequest.requestType === 2 && (
                            <div>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: t("Request.Allowance", {
                                            used: this.state.agent.holidayDaysUsed,
                                            remaining: this.state.agent.holidayDaysRemaining,
                                            allowance: this.state.agent.holidayAllowance
                                        })
                                    }}
                                ></p>
                                <div className={classes.datePicker}>
                                    <DatePicker
                                        label={t("Request.From")}
                                        name="requestStart"
                                        required
                                        error={!Boolean(this.state.newRequest.requestStart)}
                                        locale={locale}
                                        fullWidth={true}
                                        format="DD/MM/YYYY"
                                        autoOk={true}
                                        value={this.state.newRequest.requestStart}
                                        disablePast
                                        onChange={this.handleDateChangeStart}
                                    />
                                </div>

                                <div className={classes.datePicker}>
                                    <DatePicker
                                        label={t("Request.To")}
                                        name="requestEnd"
                                        required
                                        error={!Boolean(this.state.newRequest.requestEnd)}
                                        locale={locale}
                                        fullWidth={true}
                                        format="DD/MM/YYYY"
                                        autoOk={true}
                                        value={this.state.newRequest.requestEnd}
                                        disablePast
                                        onChange={this.handleDateChangeEnd}
                                    />
                                </div>
                            </div>
                        )}

                        {this.state.newRequest.requestType === 3 && (
                            <div>
                                <div className={classes.datePicker}>
                                    <DatePicker
                                        label={t("Request.Date")}
                                        name="requestDate"
                                        required
                                        error={!Boolean(this.state.newRequest.requestDate)}
                                        locale={locale}
                                        fullWidth={true}
                                        format="DD/MM/YYYY"
                                        autoOk={true}
                                        value={this.state.newRequest.requestDate}
                                        onChange={this.handleDateChange}
                                    />
                                </div>

                                <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="requestHours"> {t("Request.NumHours")}</InputLabel>
                                    <Select
                                        native
                                        value={this.state.requestHours}
                                        onChange={this.handleChange}
                                        error={!Boolean(this.state.newRequest.requestHours)}
                                        inputProps={{
                                            name: "requestHours",
                                            id: "requestHours"
                                        }}
                                    >
                                        <option value="" />
                                        <option value={0.5}> 30 {t("misc.Minutes")}</option>
                                        <option value={1}> 1 {t("misc.Hour")}</option>
                                        <option value={1.5}>1.5 {t("misc.Hours")}</option>
                                        <option value={2}>2 {t("misc.Hours")}</option>
                                        <option value={2.5}>2.5 {t("misc.Hours")}</option>
                                        <option value={3}>3 {t("misc.Hours")}</option>
                                        <option value={3.5}>3.5 {t("misc.Hours")}</option>
                                        <option value={4}>4 {t("misc.Hours")}</option>
                                    </Select>
                                </FormControl>
                            </div>
                        )}

                        <FormControl className={classes.formControl}>
                            <TextField
                                label={t("Request.Comment")}
                                multiline
                                onChange={this.handleChange}
                                error={!Boolean(this.state.newRequest.requestComment)}
                                name="requestComment"
                                value={this.state.newRequest.requestComment}
                            />
                        </FormControl>
                    </div>
                )}
            </div>
        );
    }
}

Request.propTypes = {
    classes: PropTypes.object.isRequired
};

export default translate(withAuth(withStyles(styles)(Request)));
