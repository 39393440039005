import React, { Component } from "react";
import { Route, Switch, HashRouter, Redirect } from "react-router-dom";
import "./App.css";
import * as serviceWorker from "./serviceWorker";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import ScheduleList from "./components/ScheduleList";
import Login from "./components/Login";
import Request from "./components/Request";

const theme = createMuiTheme({
    palette: {
        primary: { main: "#0d47a1", contrastText: "#ffffff" },
        secondary: { main: "#3eb3c6", contrastText: "#000000" }
    },
    typography: {
        useNextVariants: true
    }
});

class App extends Component {
    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <HashRouter>
                    <Switch>
                        <Route exact path="/" component={ScheduleList} />
                        <Route exact path="/login" component={Login} />
                        <Route exact path="/request" component={Request} />
                        <Redirect to="/" />
                    </Switch>
                </HashRouter>
            </MuiThemeProvider>
        );
    }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

export default App;
