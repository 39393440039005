import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { withStyles } from "@material-ui/core/styles";
import AccountCircle from "@material-ui/icons/AccountCircle";
import FilterList from "@material-ui/icons/FilterList";
import Send from "@material-ui/icons/Send";
import MoreIcon from "@material-ui/icons/MoreVert";
import People from "@material-ui/icons/People";
import LinearProgress from "@material-ui/core/LinearProgress";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import ReactSelect from "react-select";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Badge from "@material-ui/core/Badge";
import { CopyToClipboard } from "react-copy-to-clipboard";

import gbLocale from "@fullcalendar/core/locales/en-gb";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import momentPlugin from "@fullcalendar/moment";
import listPlugin from "@fullcalendar/list";

import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "@fullcalendar/list/main.css";

import { translate } from "react-multi-lang";

import AuthService from "../components/auth/AuthService";
import withAuth from "../components/auth/withAuth";

import fetchClient from "../components/utils/fetchClient";

const Auth = new AuthService();

function Select({ defaultValue, options, ...otherProps }) {
    return (
        <ReactSelect
            defaultValue={
                options &&
                options.length &&
                defaultValue && {
                    value: defaultValue,
                    label: options.find(o => o.value === defaultValue).label
                }
            }
            theme={theme => ({
                ...theme,
                borderRadius: 0,
                colors: {
                    ...theme.colors,
                    primary25: "#557ebd",
                    primary50: "#3d6bb3",
                    primary75: "#2559aa",
                    primary: "#0d47a1"
                }
            })}
            options={options}
            {...otherProps}
        />
    );
}

const styles = theme => ({
    root: {
        width: "100%"
    },
    grow: {
        flexGrow: 1
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    },
    sectionDesktop: {
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "flex"
        }
    },
    sectionMobile: {
        display: "flex",
        [theme.breakpoints.up("md")]: {
            display: "none"
        }
    },
    headerIcon: {
        marginRight: 5
    },
    drawer: {
        width: 250
    },
    drawerInput: {
        margin: theme.spacing()
    }
});

class ScheduleList extends React.Component {
    calendarComponentRef = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            drawerOpen: false,
            agentsList: [],
            groupsList: [],
            calendarLinkOpen: false,
            calendarLink: "",
            filters: {
                viewAgent: 0,
                viewAgentGroup: 0,
                viewlocation: 0,
                hideEvents: 0
            },
            filtersCount: 0
        };

        var decoded = Auth.getProfile();

        this.state.userName = decoded.userName;
        this.state.agentId = decoded.AgentId;
        this.state.filters.viewAgent = decoded.AgentId;

        this.state.calendarLink = process.env.REACT_APP_API_URL + "/workscheduler/ical/" + this.state.agentId;

        this.eventRender = this.eventRender.bind(this);
        this.toggleDrawer = this.toggleDrawer.bind(this);
        this.togglePopup = this.togglePopup.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.fetchEvents = this.fetchEvents.bind(this);

        this.logout = this.logout.bind(this);

        var wsViewConfig = JSON.parse(localStorage.getItem("wsViewConfig"));
        if (wsViewConfig) {
            this.state.filters = wsViewConfig;
        }

        let count = 0;
        if (this.state.filters.viewAgentGroup && this.state.filters.viewAgentGroup !== 0) {
            count++;
        }

        if (this.state.filters.viewAgent && this.state.filters.viewAgent !== 0) {
            count++;
        }

        if (this.state.filters.hideEvents && this.state.filters.hideEvents !== 0) {
            count++;
        }

        this.state.filtersCount = count;
    }

    logout() {
        Auth.logout();
        this.props.history.replace("/login");
    }

    handleProfileMenuOpen = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
        this.handleMobileMenuClose();
    };

    handleMobileMenuOpen = event => {
        this.setState({ mobileMoreAnchorEl: event.currentTarget });
    };

    handleMobileMenuClose = () => {
        this.setState({ mobileMoreAnchorEl: null });
    };

    async componentDidMount() {
        let groups = await this.fetchGroups();
        let agents = await this.fetchAgents();

        this.setState({
            groupsList: groups,
            agentsList: agents
        });
    }

    async fetchGroups() {
        return new Promise(function (resolve, reject) {
            fetchClient
                .get("groups")
                .then(function (response) {
                    resolve(response.data.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    async fetchAgents() {
        return new Promise(function (resolve, reject) {
            fetchClient
                .get("agents")
                .then(function (response) {
                    resolve(response.data.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    eventRender = arg => {
        let str;
        let eventData = arg.event.extendedProps;

        let calendarApi = this.calendarComponentRef.current.getApi();
        let type = calendarApi.view.type;

        if (type === "listDay") {
            if (eventData.type === "agentoff") {
                if (eventData.typeId === 2) {
                    arg.el.children[2].innerHTML = "<span>" + eventData.name + " (HOLIDAY)</span>";
                    arg.el.children[1].children[0].style.backgroundColor = "#800080";
                    arg.el.children[1].children[0].style.borderColor = "#800080";
                } else if (eventData.typeId === 3) {
                    arg.el.children[2].innerHTML = "<span>" + eventData.name + " (BANK HOLIDAY)</span>";
                    arg.el.children[1].children[0].style.backgroundColor = "#800080";
                    arg.el.children[1].children[0].style.borderColor = "#800080";
                } else if (eventData.typeId === 4) {
                    arg.el.children[2].innerHTML = "<span>" + eventData.name + " (SICK)</span>";
                    arg.el.children[1].children[0].style.backgroundColor = "#008000";
                    arg.el.children[1].children[0].style.borderColor = "#008000";
                } else {
                    arg.el.children[2].innerHTML = "<span>" + eventData.name + " (OFF)</span>";
                    arg.el.children[1].children[0].style.backgroundColor = "#ff0000";
                    arg.el.children[1].children[0].style.borderColor = "#ff0000";
                }
            } else if (eventData.type === "task") {
                arg.el.children[2].innerHTML = "<span>" + arg.event.title + " (TASK)</span>";
                arg.el.children[1].children[0].style.backgroundColor = "#1589FF";
                arg.el.children[1].children[0].style.borderColor = "#1589FF";
            } else if (eventData.type === "checkin") {
                arg.el.children[2].innerHTML = "<span>" + arg.event.title + " (CHECKIN)</span>";
                arg.el.children[1].children[0].style.backgroundColor = "#00aa00";
                arg.el.children[1].children[0].style.borderColor = "#00aa00";
            } else if (eventData.type === "checkout") {
                arg.el.children[2].innerHTML = "<span>" + arg.event.title + " (CHECKOUT)</span>";
                arg.el.children[1].children[0].style.backgroundColor = "#ff0000";
                arg.el.children[1].children[0].style.borderColor = "#ff0000";
            } else if (eventData.type === "agentevent") {
                arg.el.children[2].innerHTML = "<span>" + eventData.name + " (WORKING)</span>";
            }

            return arg.el;
        }

        //Location and description
        if (eventData.location) {
            arg.el.children[0].innerHTML += "<div>" + eventData.location + "</div>";
        }

        if (eventData.description) {
            arg.el.children[0].innerHTML += "<div>" + eventData.description + "</div>";
        }

        if (eventData.type === "agentevent") {
            //Agent color
            arg.el.style.backgroundColor = eventData.color;
            arg.el.style.borderColor = eventData.color;
        } else if (eventData.type === "agentoff") {
            if (eventData.typeId === 2) {
                arg.el.children[0].innerHTML += "<div>HOLIDAY</div>";
                arg.el.style.backgroundColor = "#800080";
                arg.el.style.borderColor = "#800080";
            } else if (eventData.typeId === 3) {
                arg.el.children[0].innerHTML += "<div>BANK HOLIDAY</div>";
                arg.el.style.backgroundColor = "#800080";
                arg.el.style.borderColor = "#800080";
            } else if (eventData.typeId === 4) {
                arg.el.children[0].innerHTML += "<div>SICK</div>";
                arg.el.style.backgroundColor = "#008000";
                arg.el.style.borderColor = "#008000";
            } else {
                arg.el.children[0].innerHTML += "<div>OFF</div>";
                arg.el.style.backgroundColor = "#ff0000";
                arg.el.style.borderColor = "#ff0000";
            }
        } else if (eventData.type === "task") {
            if (eventData.isExact === 1) {
                arg.el.children[0].innerHTML = '<i class="fas fa-star fa-lg"></i>' + arg.el.children[0].innerHTML;
            }

            if (eventData.done === 1) {
                arg.el.style.textDecoration = "line-through";
            }

            arg.el.style.backgroundColor = "#1589FF";
            arg.el.style.borderColor = "#1589FF";
        } else if (eventData.type === "checkin") {
            var lang = eventData.lang === "en" ? "gb" : eventData.lang;

            str = '<i class="flag-icon flag-icon-' + lang + '"></i> ';

            if (eventData.country === "cn") {
                str += '<i class="flag-icon flag-icon-' + eventData.country + '"></i> ';
            }

            str += '<i class="fas fa-sign-in-alt fa-lg"></i> ';

            if (eventData.isExact === 1) {
                str += ' <i class="fas fa-star fa-lg"></i> ';
            }

            if (eventData.hasCheckout === 0) {
                str += ' <i class="fas fa-check fa-lg"></i> ';
            }

            arg.el.children[0].children[0].innerHTML = str;

            arg.el.style.backgroundColor = "#00aa00";
            arg.el.style.borderColor = "#00aa00";
        } else if (eventData.type === "checkout") {
            var channelId = eventData.channelid;

            var icon = "globe";
            if (channelId === 1) {
                icon = "bookingcom";
            } else if (channelId === 7) {
                icon = "airbnb";
            }

            str = ' <i class="fas fa-' + icon + '"></i> <i class="fas fa-sign-out-alt fa-lg"></i> ';

            arg.el.children[0].children[0].innerHTML = str;

            arg.el.style.backgroundColor = "#ff0000";
            arg.el.style.borderColor = "#ff0000";
        }

        return arg.el;
    };

    toggleDrawer = open => () => {
        this.setState({
            drawerOpen: open
        });

        if (!open) {
            this.handleMenuClose();
        }
    };

    togglePopup = open => () => {
        this.setState({
            calendarLinkOpen: open
        });

        if (!open) {
            this.handleMenuClose();
        }
    };

    fetchEvents = (info, successCallback, failureCallback) => {
        var self = this;
        if (self.state.isLoading) {
            fetchClient
                .post("/workscheduler/list", {
                    start: info.startStr,
                    end: info.endStr,
                    agentId: self.state.filters.viewAgent,
                    agentGroupId: self.state.filters.viewAgentGroup,
                    hideEvents: self.state.filters.hideEvents
                })
                .then(function (response) {
                    successCallback(response.data.data);
                })
                .catch(function (error) {
                    failureCallback(error);
                    console.log(error);
                });
        }
    };

    handleSelect = (val, event) => {
        this.setState({ filters: { ...this.state.filters, [event.name]: val ? val.value : null }, isLoading: true, drawerOpen: false }, () => {
            localStorage.setItem("wsViewConfig", JSON.stringify(this.state.filters));

            this.handleMenuClose();

            let count = 0;
            if (this.state.filters.viewAgentGroup && this.state.filters.viewAgentGroup !== 0) {
                count++;
            }

            if (this.state.filters.viewAgent && this.state.filters.viewAgent !== 0) {
                count++;
            }

            if (this.state.filters.hideEvents && this.state.filters.hideEvents !== 0) {
                count++;
            }

            this.setState({ filtersCount: count });

            let calendarApi = this.calendarComponentRef.current.getApi();
            calendarApi.refetchEvents();
        });
    };

    handleToggle = event => {
        this.setState({ filters: { ...this.state.filters, hideEvents: event.target.checked ? 1 : 0 }, isLoading: true, drawerOpen: false }, () => {
            localStorage.setItem("wsViewConfig", JSON.stringify(this.state.filters));

            this.handleMenuClose();

            let count = 0;
            if (this.state.filters.viewAgentGroup && this.state.filters.viewAgentGroup !== 0) {
                count++;
            }

            if (this.state.filters.viewAgent && this.state.filters.viewAgent !== 0) {
                count++;
            }

            if (this.state.filters.hideEvents && this.state.filters.hideEvents !== 0) {
                count++;
            }

            this.setState({ filtersCount: count });

            let calendarApi = this.calendarComponentRef.current.getApi();
            calendarApi.refetchEvents();
        });
    };

    render() {
        const { anchorEl, mobileMoreAnchorEl } = this.state;
        const { classes, t } = this.props;
        const isMenuOpen = Boolean(anchorEl);
        const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

        const renderMenu = (
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={isMenuOpen}
                onClose={this.handleMenuClose}
            >
                <MenuItem onClick={this.togglePopup(true)}>
                    <p>{t("nav.CalendarLink")}</p>
                </MenuItem>
                <MenuItem onClick={this.logout}>
                    <p>{t("nav.Logout", { param: this.state.userName })}</p>
                </MenuItem>
            </Menu>
        );

        const renderMobileMenu = (
            <Menu
                anchorEl={mobileMoreAnchorEl}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={isMobileMenuOpen}
                onClose={this.handleMobileMenuClose}
            >
                <MenuItem onClick={this.handleProfileMenuOpen}>
                    <IconButton color="inherit">
                        <AccountCircle />
                    </IconButton>
                    <p>{t("nav.User")}</p>
                </MenuItem>

                <MenuItem onClick={this.toggleDrawer(true)}>
                    <IconButton color="inherit">
                        <FilterList />
                    </IconButton>
                    <p>
                        {t("nav.Filters")} {this.state.filtersCount > 0 ? "(" + this.state.filtersCount + ")" : null}{" "}
                    </p>
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        this.props.history.push("/request/");
                    }}
                >
                    <IconButton color="inherit">
                        <Send />
                    </IconButton>
                    <p>{t("nav.Request")} </p>
                </MenuItem>
            </Menu>
        );

        return (
            <div className={classes.root}>
                <AppBar position="static">
                    <Toolbar>
                        <People className={classes.headerIcon} />
                        <Typography variant="h6" color="inherit">
                            workSchedulerApp
                        </Typography>

                        <div className={classes.grow} />
                        <div className={classes.sectionDesktop}>
                            <IconButton aria-owns={isMenuOpen ? "material-appbar" : undefined} aria-haspopup="true" onClick={this.toggleDrawer(true)} color="inherit">
                                <Badge badgeContent={this.state.filtersCount} color="secondary">
                                    <FilterList />
                                </Badge>
                            </IconButton>
                            <IconButton
                                aria-owns={isMenuOpen ? "material-appbar" : undefined}
                                aria-haspopup="true"
                                onClick={() => {
                                    this.props.history.push("/request/");
                                }}
                                color="inherit"
                            >
                                <Send />
                            </IconButton>
                            <IconButton aria-owns={isMenuOpen ? "material-appbar" : undefined} aria-haspopup="true" onClick={this.handleProfileMenuOpen} color="inherit">
                                <AccountCircle />
                            </IconButton>
                        </div>
                        <div className={classes.sectionMobile}>
                            <IconButton aria-haspopup="true" onClick={this.handleMobileMenuOpen} color="inherit">
                                <MoreIcon />
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>

                {renderMenu}
                {renderMobileMenu}

                {this.state.isLoading && <LinearProgress color="secondary" />}

                <div>
                    <div className="calendar">
                        <FullCalendar
                            height="auto"
                            lazyFetching={true}
                            scrollTime="08:00:00"
                            locale={gbLocale}
                            titleFormat={{ year: "2-digit", month: "numeric", day: "numeric" }}
                            defaultView="timeGridDay"
                            weekends={true}
                            loading={status => this.setState({ isLoading: status })}
                            buttonText={{
                                today: "Today",
                                month: "Month",
                                week: "Week",
                                day: "Day",
                                list: "Agenda"
                            }}
                            slotDuration={{ hours: 1 }}
                            slotLabelFormat={{
                                hour: "2-digit",
                                hour12: false
                            }}
                            header={{
                                left: "prev,today",
                                center: "title",
                                right: "next"
                            }}
                            footer={{
                                left: "",
                                right: "",
                                center: "dayGridMonth,timeGridWeek,timeGridDay,listDay"
                            }}
                            firstDay={1}
                            eventRender={this.eventRender}
                            eventLimit={true}
                            plugins={[dayGridPlugin, timeGridPlugin, momentPlugin, listPlugin]}
                            ref={this.calendarComponentRef}
                            events={this.fetchEvents}
                            eventTimeFormat={{
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: false
                            }}
                        />
                    </div>

                    <SwipeableDrawer anchor="right" open={this.state.drawerOpen} onClose={this.toggleDrawer(false)} onOpen={this.toggleDrawer(true)}>
                        <div tabIndex={0} role="button" className={classes.drawer}>
                            <div className={classes.drawerInput}>
                                <Typography variant="h5" color="inherit">
                                    {t("Scheduler.Filters")}
                                </Typography>

                                <Typography variant="h6" color="inherit">
                                    {t("Scheduler.Agent")}
                                </Typography>

                                <Select
                                    isClearable
                                    name="viewAgent"
                                    options={this.state.agentsList.map(option => ({
                                        value: option.agentId,
                                        label: option.userName
                                    }))}
                                    defaultValue={this.state.filters.viewAgent}
                                    classNamePrefix="react-select"
                                    onChange={this.handleSelect}
                                />
                            </div>

                            <div className={classes.drawerInput}>
                                <Typography variant="h6" color="inherit">
                                    {t("Scheduler.Group")}
                                </Typography>

                                <Select
                                    isClearable
                                    name="viewAgentGroup"
                                    defaultValue={this.state.filters.viewAgentGroup}
                                    options={this.state.groupsList.map(option => ({
                                        value: option.agentGroupId,
                                        label: option.groupName
                                    }))}
                                    classNamePrefix="react-select"
                                    onChange={this.handleSelect}
                                />
                            </div>

                            <div className={classes.drawerInput}>
                                <Typography variant="h6" color="inherit">
                                    {t("Scheduler.Events")}
                                </Typography>

                                <FormControlLabel
                                    control={<Switch checked={Boolean(this.state.filters.hideEvents)} onChange={this.handleToggle} value="hideEvents" color="primary" />}
                                    label={t("Scheduler.HideEvents")}
                                />
                            </div>
                        </div>
                    </SwipeableDrawer>
                </div>

                <Dialog open={this.state.calendarLinkOpen} onClose={this.togglePopup(false)} aria-labelledby="calendar-link-dialog-title">
                    <DialogTitle id="calendar-link-dialog-title">{t("Scheduler.Calendar")}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{t("Scheduler.SubscribeHelp")}</DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="link"
                            value={this.state.calendarLink}
                            label="Your Link"
                            type="text"
                            InputProps={{
                                readOnly: true
                            }}
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button href={"webcal://" + this.state.calendarLink.replace("https://", "")}>{t("Scheduler.Subscribe")}</Button>
                        <CopyToClipboard text={this.state.calendarLink}>
                            <Button color="primary">{t("misc.Copy")}</Button>
                        </CopyToClipboard>
                        <Button onClick={this.togglePopup(false)}>{t("misc.Close")}</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

ScheduleList.propTypes = {
    classes: PropTypes.object.isRequired
};

export default translate(withAuth(withStyles(styles)(ScheduleList)));
